<template>
  <div class="col-box" style="height: 100vh">
      <PlayerFull ref="liveStream" />
      
      <!--开闸、关闸、抓拍-->
      <div style="margin: 15px; text-align: left;">
        <div class="col-box-btn" style="margin-left: 0;" @click="openGate()">
          <img src="../assets/open_gate.png" style="width: 25px;height: 25px; vertical-align: middle;" />
          <span style="vertical-align: middle;">开闸</span>
        </div>
        <div class="col-box-btn" @click="closeGate()">
          <img src="../assets/close_gate.png" style="width: 25px;height: 25px; vertical-align: middle;" />
          <span style="vertical-align: middle;">落闸</span>
        </div>
        <div class="col-box-btn" @click="capturePhone()">
          <img src="../assets/capture.png" style="width: 25px;height: 25px; vertical-align: middle;" />
          <span style="vertical-align: middle;">抓拍</span>
        </div>
      </div>
      <div>
        <NavTabs @bindTabItemClickEvent="onClickTabItemEvent">
          <CameraLiveList
            v-if="activeTabIndex == 0"
            :roadId="roadId"
            :videoId="curVideoId"
            @bindSelectVideoItemEvent="onSelectVideoItemEvent"
          />
          <CameraImageList
            :parkingId="parkingId"
            :roadId="roadId"
            v-if="activeTabIndex == 1"
          />
          <CameraLogList
            :parkingId="parkingId"
            :roadId="roadId"
            v-if="activeTabIndex == 2"
          />
        </NavTabs>
      </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import PlayerFull from "@/components/PlayerFull.vue";
import NavTabs from "@/components/NavTabs.vue";
import CameraLogList from "@/components/listing/CameraLogList.vue";
import CameraLiveList from "@/components/listing/CameraLiveList.vue";
import CameraImageList from "@/components/listing/CameraImageList.vue";
import { CameraVideo } from "@/views/Bean";
import { requestMonitorStream } from "@/api/HttpRequest";
import { showToastSuccess, showToastError } from "@/utils/dialog";
import { requestOpenGate, requestCloseGate, requestCameraCapture } from "@/api/HttpRequest";
import store from "@/stroage/localStorage";

@Options({
  components: {
    PlayerFull,
    NavTabs,
    CameraLogList,
    CameraLiveList,
    CameraImageList
  },
  created() {
    const route = this.$route.query;
    this.roadId = route.roadId;
    this.parkingId = route.parkingId;
    document.title = route.title || '图片列表'
    store.setToken(route.token);
  },

  mounted() {
    // test
    // this.liveStream.playVideoStreamEvent(
    //   "http://220.161.87.62:8800/hls/0/index.m3u8"
    // );
  },
})
export default class HomeView extends Vue {
  liveStream: any = null;
  parkingId!: string;
  roadId!: string;
  activeTabIndex: number = 1;
  curVideoId = 0;

  onClickTabItemEvent(index: number) {
    this.activeTabIndex = index;
  }
  onSelectVideoItemEvent(data: CameraVideo) {
    const params = {
      equId: data.id,
    };
    requestMonitorStream(params, (res: any) => {
      // const videoUrl = "http://220.161.87.62:8800/hls/0/index.m3u8";
      if (!res) {
        showToastError("未获取到视频");
        return;
      }
      this.curVideoId = data.id;
      this.liveStream.playVideoStreamEvent(res);
    });
  }

  openGate() {
    const params = {
      roadId: this.roadId,
    };

    requestOpenGate(params, (res: any) => {
      if (res) {
        showToastSuccess("开闸成功");
      }
    });
  }
  closeGate() {
    const params = {
      roadId: this.roadId,
    };

    requestCloseGate(params, (res: any) => {
      if (res) {
        showToastSuccess("关闸成功");
      }
    });
  }
  //抓拍
  capturePhone() {
    const params = {
      roadId: this.roadId,
    };

    requestCameraCapture(params, (res: any) => {
      if (res) {
        showToastSuccess("抓拍成功");
      }
    });
  }
}
</script>
<style scoped>
  .col-box-btn{
    display: inline-block;
    text-align: center;
    width: 20%;
    border:1px solid;
    padding:10px;
    border-radius: 10px;
    margin-left: 10%;
  }
</style>
