<template>
  <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onMoreLoad">

    <van-grid :column-num="3">
      <van-grid-item v-for="item in listData" :key="item.id" @click="onClickSelectItemEvent(item)">
        <template #text>
          <div>
            <img v-if="item.id == videoId" src="../../assets/ic_monitor_blue.png"
              style="width: 40px;height: 40px;" />
            <img v-else src="../../assets/ic_monitor.png" style="width: 40px;height: 40px;" />
            <div :style="item.id == videoId? 'color:cornflowerblue':'color:black'">
              {{ item.name }}</div>
          </div>
        </template>
      </van-grid-item>
    </van-grid>

  </van-list>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ExtendsList from "./ExtendsList";
import { requestMonitorVideoList } from "@/api/HttpRequest";
import { CameraVideo } from "@/views/Bean";

@Options({
  props: {
    roadId: String,
    videoId: Number,
  },
  created() {
    this.curRoadId = this.roadId;
    this.httpRequestMonitorList(this.curRoadId);
    console.log("----->videoId: ", this.videoId);
  },
})
export default class CameraLiveList extends ExtendsList {
  curRoadId!: string;
  listData: CameraVideo[] = [];
  videoId!: number;

  onMoreLoad = () => {
    this.loadingFinished();
  };

  onClickSelectItemEvent = (item: CameraVideo) => {
    this.$emit("bindSelectVideoItemEvent", item);
  };

  httpRequestMonitorList(roadId: string) {
    const params = {
      roadId: roadId,
    };

    requestMonitorVideoList(params, (res: any) => {
      this.listData = res;
      if(res && res.length > 0){
        this.onClickSelectItemEvent(res[0]);
      }
    });
  }
}
</script>

<style scoped>
</style>