import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import loadVant from "./plugins/vant";
import "./assets/css/global.css";
// 引入vant组件样式
import "vant/lib/index.css";
const app: any = createApp(App);
loadVant(app);
app.use(router).mount("#app");
