/**
 * https://www.axios-http.cn/docs/intro
 */
import axios from "axios";
import store from "@/stroage/localStorage";
axios.defaults.baseURL = "/api";
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.timeout = 5000; // 超时时间5s

// 请求和相应拦截器
// 添加请求拦截器
axios.interceptors.request.use(
  function (config: any) {
    var token = store.getToken();
    // 在发送请求之前做些什么
    if (token) {
      config.headers["sessionId"] = token;
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default axios;
