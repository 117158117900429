<template>
  <van-cell @click="onClickSelectDateEvent">
    <template #title>
      <div class="cell-custom">
        <van-icon name="clock-o" class="search-icon" />
        <div>{{ startTimeStanding }} - 当前</div>
      </div>
    </template>
  </van-cell>

  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onMoreLoad"
  >
    <van-cell v-for="item in listData" :key="item">
      <template #title>
        <div class="row-box">
          {{ item }}
        </div>
      </template>
    </van-cell>
  </van-list>

  <van-popup :show="showBottomPopup" position="bottom">
    <DateTimePicker
      title="选择日期"
      @bindConfirm="confirmSelectDate"
      @bindCancel="cancelSelectDate"
    />
  </van-popup>
</template>

<script lang="ts">
import { Options } from "vue-class-component";
import ExtendsList from "./ExtendsList";
import { requestCameraLogList } from "@/api/HttpRequest";
import DateTimePicker from "@/components/DateTimePicker.vue";
@Options({
  props: {
    parkingId: String,
    roadId: String,
  },
  components: {
    DateTimePicker,
  },
  created() {
    console.log("--->created ", this.parkingId);
    this.roadIdStanding = this.roadId;
    this.parkingIdStanding = this.parkingId;

    const date = new Date();
    this.startTimeStanding = `${date.getFullYear()}-${this.dateMathZero(
      date.getMonth() + 1
    )}-${this.dateMathZero(date.getDate())} ${this.dateMathZero(
      date.getHours()
    )}:${this.dateMathZero(date.getMinutes())}`;
  },
})
export default class CameraLogLsit extends ExtendsList {
  showBottomPopup: boolean = false;
  parkingId!: string;
  roadId!: string;
  roadIdStanding = "";
  parkingIdStanding = "";
  startTimeStanding = "";
  listData: string[] = [];
  requestIndex = 1;

  onClickSelectDateEvent = () => {
    this.showBottomPopup = true;
  };
  confirmSelectDate = (datetime: any) => {
    this.showBottomPopup = false;
    this.startTimeStanding = datetime;
    this.requestIndex = 1;
    this.listData = [];
    this.httpRequestVideoLogs();
  };
  cancelSelectDate = () => {
    this.showBottomPopup = false;
  };

  onMoreLoad = () => {
    this.httpRequestVideoLogs();
  };

  httpRequestVideoLogs() {
    const params = {
      roadId: this.roadIdStanding,
      parkingId: this.parkingIdStanding,
      pageNo: this.requestIndex,
      startTime: `${this.startTimeStanding}:00`,
      pageSize: 10,
    };
    requestCameraLogList(params, (res: any) => {
      this.listData = this.listData.concat(res);
      this.loadingSuccess();
      this.requestIndex++;
      if (res.length < params.pageSize) {
        this.loadingFinished();
      }
    });
  }

  dateMathZero = (num: number) => (num >= 10 ? num : `0${num}`);
}
</script>

<style scoped>
.cell-custom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  color: cornflowerblue;
}
</style>
