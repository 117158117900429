<template>
  <van-picker
    :title="title"
    v-model="selectedValues"
    :columns="multiDateTime"
    @confirm="onConfirm"
    @cancel="onCancel"
    @change="onChange"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { DateTimeRange } from "@/views/Bean";
@Options({
  props: {
    title: String,
    start: String,
    end: String,
  },
  emits: ["bindConfirm", "bindCancel"],
  created() {
    this.endDate = this.end ? this._stringToDate(this.end) : new Date(); // 延后一月
    this.startDate = this.start
      ? this._stringToDate(this.start)
      : new Date(this.endDate.getTime() - 1000 * 60 * 60 * 24 * 30);
    this.selectedValues = this._getNowDateTime();
  
    this.multiple = this._getRangeDateTime(this.startDate, this.endDate);
    console.log("--->multiple: ", this.multiple);
    
    for (var i = 0; i < this.multiple.length; i++) {
      const v = this.multiple[i];
      this.multiDateTime.push(this._getLoopArray(v.start, v.end, v.name));
    }
  },
})
export default class DateTimePicker extends Vue {
  title!: string;
  start!: string;
  end!: string;
  startDate!: Date;
  endDate!: Date;

  selectedValues: string[] = [];
  multiDateTime: any[] = [];

  multiple: DateTimeRange[] = [];
  onConfirm(event: any) {
    const datetime = `${event.selectedOptions[0].value}-${event.selectedOptions[1].value}-${event.selectedOptions[2].value} ${event.selectedOptions[3].value}:${event.selectedOptions[4].value}`;
    console.log("-->datetime: ", datetime);
    this.$emit("bindConfirm", datetime);
  }
  onCancel() {
    this.$emit("bindCancel");
  }
  onChange(event: any) {
    const index: number = event.columnIndex;
    const count: number = this.multiDateTime.length;
    if (index == count) {
      return;
    }
    this.multiple = this._getRangeDateTime(this.startDate, this.endDate);
    for (var i = index + 1; i < count; i++) {
      const v = this.multiple[i];
      this.multiDateTime[i] = this._getLoopArray(v.start, v.end, v.name);
    }
  }

  _getNowDateTime() {
    const now = new Date();
    const arr: string[] = [
      this._withData(now.getFullYear()),
      this._withData(now.getMonth() + 1),
      this._withData(now.getDate()),
      this._withData(now.getHours()),
      this._withData(now.getMinutes()),
    ];
    return arr;
  }

  /** 获取选取范围 */
  _getRangeDateTime(startDate: Date, endDate: Date) {
    // 计算开始点
    let startMonth = 1;
    let startDay = 1;
    let startHour = 0;
    let startMinute = 0;
    if (parseInt(this.selectedValues[0]) == startDate.getFullYear()) {
      startMonth = startDate.getMonth() + 1;
      if (parseInt(this.selectedValues[1]) == startDate.getMonth() + 1) {
        startDay = startDate.getDate();
        if (parseInt(this.selectedValues[2]) == startDate.getDate()) {
          startHour = startDate.getHours();
          if (parseInt(this.selectedValues[3]) == startDate.getHours()) {
            startMinute = startDate.getMinutes();
          }
        }
      }
    }

    // 计算结束点
    let endMonth = 12;
    let endHour = 23;
    let endMinute = 59;

    console.log("----->selectedValues: ", this.selectedValues);
    
    let endDay = this._getMonthDay(
      parseInt(this.selectedValues[0]),
      parseInt(this.selectedValues[1])
    );
   

    if (parseInt(this.selectedValues[0]) == endDate.getFullYear()) {
      endMonth = endDate.getMonth() + 1;
      if (parseInt(this.selectedValues[1]) == endDate.getMonth() + 1) {
        console.log("----->endDate: ", endDate);
        endDay = endDate.getDate();
        console.log("----->endDate2: ", endDay);
        if (parseInt(this.selectedValues[2]) == endDate.getDate()) {
          endHour = endDate.getHours();
          if (parseInt(this.selectedValues[3]) == endDate.getHours()) {
            endMinute = endDate.getMinutes();
          }
        }
      }
    }
    
    return <DateTimeRange[]>[
      {
        start: startDate.getFullYear(),
        end: endDate.getFullYear(),
        name: "年",
      },
      { start: startMonth, end: endMonth, name: "月" },
      {
        start: startDay,
        end: endDay,
        name: "日",
      },
      { start: startHour, end: endHour, name: "时" },
      { start: startMinute, end: endMinute, name: "分" },
    ];
  }

  /* 是否闰年 */
  _isLeapYear(year: number) {
    const flag: boolean = year % 400 == 0 || (year % 4 == 0 && year % 100 != 0);
    return flag;
  }

  /* 获取起始数组 */
  _getLoopArray(start: number, end: number, name: string) {
    name = name || "";
    start = start || 0;
    end = end || 1;
    const array: any[] = [];
    for (var i = start; i <= end; i++) {
      const value: string = this._withData(i);
      array.push({
        text: value + name,
        value: value,
      });
    }
    return array;
  }

  _stringToDate(str: string) {
    str = str.replace(/-/g, "/");
    return new Date(str);
  }

  /* 数据左边加0 */
  _withData(param: number) {
    return param < 10 ? "0" + param : "" + param;
  }

  /* 获取每月天数 */
  _getMonthDay(year: number, month: number) {
    switch (month) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        return 31;

      case 4:
      case 6:
      case 9:
      case 11:
        return 30;

      case 2:
        return this._isLeapYear(year) ? 29 : 28;
    }
    return 0;
  }
}
</script>
