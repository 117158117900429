import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_picker = _resolveComponent("van-picker")!

  return (_openBlock(), _createBlock(_component_van_picker, {
    title: _ctx.title,
    modelValue: _ctx.selectedValues,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValues) = $event)),
    columns: _ctx.multiDateTime,
    onConfirm: _ctx.onConfirm,
    onCancel: _ctx.onCancel,
    onChange: _ctx.onChange
  }, null, 8, ["title", "modelValue", "columns", "onConfirm", "onCancel", "onChange"]))
}