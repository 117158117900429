// 登录识别
const KEY_TOKEN = "token";
// 用户信息
const KEY_USER = "real_user";

var store = {
  setStorage(key: string, value: string) {
    window.localStorage.setItem(key, value);
  },

  getStorage(key: string) {
    return window.localStorage.getItem(key);
  },

  setToken(token: string) {
    this.setStorage(KEY_TOKEN, token);
  },

  getToken() {
    return this.getStorage(KEY_TOKEN);
  },

  setUser(user: string) {
    this.setStorage(KEY_USER, user);
  },

  getUser() {
    return this.getStorage(KEY_USER);
  },
};

export default store;
