import { Options, Vue } from "vue-class-component";
@Options({
  created() {
    console.log("----->created-ExtendsList");
  },
})
export default class ExtendsList extends Vue {
  loading: boolean = false;
  finished: boolean = false;

  loadingSuccess() {
    this.finished = false;
    this.loading = false;
  }
  loadingFinished() {
    this.finished = true;
    this.loading = false;
  }
}
