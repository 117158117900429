import {
  Button,
  Toast,
  Dialog,
  Tab,
  Tabs,
  List,
  Cell,
  Divider,
  Popup,
  Icon,
  Grid,
  GridItem,
  DatePicker,
  Picker
} from "vant";

const loadVant = (app: any) => {
  app
    .use(Button)
    .use(Tab)
    .use(Tabs)
    .use(Dialog)
    .use(Toast)
    .use(List)
    .use(Cell)
    .use(Popup)
    .use(Icon)
    .use(DatePicker)
    .use(Grid)
    .use(GridItem)
    .use(Picker)
    .use(Divider);
};

export default loadVant;
