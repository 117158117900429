import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  style: {"width":"100%"},
  ref: "videoElement",
  src: "videoSrc",
  muted: "",
  autoplay: "",
  controls: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("video", _hoisted_1, null, 512))
}