import axios from "@/plugins/axios";
import { showLoading, closeLoading, showToastError } from "../../utils/dialog";

export function httpGet(
  url: string,
  params: any,
  success: Function,
  fail: Function
) {
  showLoading("加载中...");
  var headers = params["headers"];
  if (!headers) {
    headers = {};
  }
  delete params["headers"];
  axios
    .get(url, { headers, params })
    .then((res: any) => {
      closeLoading();
      var data = res.data;
      if (data.status == 200 || data.code == 0) {
        success && success(data.data);
        return;
      }
      failCallback(data, fail);
    })
    .catch((res: any) => {
      closeLoading();
      failCallback(res, fail);
    });
}

export function httpDelete(
  url: string,
  params: object,
  success: Function,
  fail: Function
) {
  showLoading("加载中...");
  axios
    .delete(url, { params })
    .then((res) => {
      closeLoading();
      var data = res.data;
      if (data.status == 200 || data.code == 0) {
        success && success(data.data);
        return;
      }
      failCallback(data, fail);
    })
    .catch((res) => {
      closeLoading();
      failCallback(res, fail);
    });
}

export function httpPost(
  url: string,
  params: any,
  success: Function,
  fail: Function
) {
  showLoading("加载中...");
  var headers = params["headers"];
  if (!headers) {
    headers = {};
  }
  delete params["headers"];
  axios
    .post(url, params, { headers })
    .then((res: any) => {
      closeLoading();
      var data = res.data;
      if (data.status == 200 || data.code == 0) {
        success && success(data.data);
        return;
      }
      failCallback(data, fail);
    })
    .catch((res: any) => {
      closeLoading();
      failCallback(res, fail);
    });
}

/**
 * 导出文件
 * @param url
 * @param params
 * @param success
 * @param fail
 */
export function httpExport(
  url: string,
  params: any,
  success: Function,
  fail: Function
) {
  axios
    .post(url, params, {
      responseType: "blob", //二进制流
    })
    .then((res: any) => {
      closeLoading();
      const disposition = res.headers["content-disposition"];
      if (disposition) {
        const fileName = window.decodeURI(disposition.split("=")[1]);
        let blob = new Blob([res.data], { type: "application/x-download" });
        const link = document.createElement("a"); // 创建元素
        link.style.display = "none";
        link.href = URL.createObjectURL(blob); // 创建下载的链接
        link.setAttribute("download", fileName); // 给下载后的文件命名
        document.body.appendChild(link);
        link.click(); // 点击下载
        document.body.removeChild(link); //  下载完成移除元素
        window.URL.revokeObjectURL(link.href); // 释放掉blob对象
      } else {
        failCallback(res, fail);
      }
    })
    .catch((res: any) => {
      closeLoading();
      failCallback(res, fail);
    });
}

/**
 * 上传文件
 * @param {*} url
 * @param {*} file
 * @param {*} success
 * @param {*} fail
 */
export function httpPostFile(
  url: string,
  file: File,
  success: Function,
  fail: Function
) {
  showLoading("加载中...");
  const formData = new FormData();
  formData.append("file", file);
  var header: any = { "Content-type": "multipart/form-data" };
  axios
    .post(url, formData, header)
    .then((res: any) => {
      closeLoading();
      var data = res;
      if (data.status == 200 || data.code == 0) {
        success && success(data.data);
        return;
      }
      failCallback(data, fail);
    })
    .catch((res: any) => {
      closeLoading();
      failCallback(res, fail);
    });
}

function failCallback(data: any, fail: Function) {
  console.log("---->请求失败：", data);
  if (data.code == 2001) {
    // token 失效
    showToastError("登录失效");
    // window.location.replace("/loginView")
    return;
  }
  showToastError(data.msg);
  fail && fail(data);
}
