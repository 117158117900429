import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bfc0e658"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cell-custom" }
const _hoisted_2 = { class: "row-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_list = _resolveComponent("van-list")!
  const _component_DateTimePicker = _resolveComponent("DateTimePicker")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_cell, { onClick: _ctx.onClickSelectDateEvent }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_van_icon, {
            name: "clock-o",
            class: "search-icon"
          }),
          _createElementVNode("div", null, _toDisplayString(_ctx.startTimeStanding) + " - 当前", 1)
        ])
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_van_list, {
      loading: _ctx.loading,
      "onUpdate:loading": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loading) = $event)),
      finished: _ctx.finished,
      "finished-text": "没有更多了",
      onLoad: _ctx.onMoreLoad
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listData, (item) => {
          return (_openBlock(), _createBlock(_component_van_cell, { key: item }, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, _toDisplayString(item), 1)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["loading", "finished", "onLoad"]),
    _createVNode(_component_van_popup, {
      show: _ctx.showBottomPopup,
      position: "bottom"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DateTimePicker, {
          title: "选择日期",
          onBindConfirm: _ctx.confirmSelectDate,
          onBindCancel: _ctx.cancelSelectDate
        }, null, 8, ["onBindConfirm", "onBindCancel"])
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}