export default {
  // 相机日志
  CAMERA_LOG_LIST: "/mini/park/log/by_road",
  //道口监控设备列表
  CAMERA_MONITOR_LIST: "/mini/park/road/equ/monitor",
  //单个摄像头获取流地址
  MONITOR_PULL_STREAM: "/mini/park/road/equ/monitor/pullStream",
  //图片抓拍
  CAMERA_PHOTO_CAPTURE: "/mini/b/park/auth_record/remoteCapture",
  //图片抓拍列表
  CAMERA_PHOTO_LIST: "/mini/park/log/captrueList",
  //手动关闸
  CLOSE_GATE: "/mini/b/park/auth_record/remoteCloseGate",
  //手动开闸
  OPEN_GATE: "/mini/b/park/auth_record/remoteOpenGate"
};
