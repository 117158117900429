<template>

  <video style="width: 100%;" ref="videoElement" src="videoSrc" muted autoplay controls></video>
</template>
<!-- <script src="https://cdn.bootcdn.net/ajax/libs/flv.js/1.6.1/flv.min.js"></script> -->
<!-- <script src="https://cdn.jsdelivr.net/npm/hls.js@latest"></script>  -->
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import flvjs from "flv.js";
let Hls = require("hls.js");
@Options({
  props: {},
  expose: ["playVideoStreamEvent"],
  // watch: {
  //   videoSrc: {
  //     handler(newVal, oldVal) {
  //       if (newVal && newVal !== oldVal) {
  //         this.videoSrc = newVal;
  //         this.playVideo();
  //       }
  //     },
  //   },
  // },
  created() {},
  mounted() {
    this.videoDom = this.$refs.videoElement;
  },
  beforeUnmount() {
    this.releaseFlvVide();
  },
})
export default class PlayerFull extends Vue {
  videoSrc: string = "";
  videoDom: any = null;
  flvPlayer: any = null;

  playHlsVideo() {
    if(!this.videoSrc){
      return
    }
    if (Hls.isSupported()) {
      let hls = new Hls();
      hls.loadSource(this.videoSrc);
      hls.attachMedia(this.videoDom);
      hls.on(Hls.Events.MANIFEST_PARSED, (event: any, data: any) => {
        this.videoDom.play();
      });
    }else if (this.videoDom.canPlayType('application/vnd.apple.mpegurl')) {
      this.videoDom.src = this.videoSrc;
    }
  }
  playFlvVideo() {
    if (flvjs.isSupported()) {
      this.releaseFlvVide();
      this.flvPlayer = flvjs.createPlayer({
        type: "flv",
        url: this.videoSrc,
        isLive: true,
      });
      this.flvPlayer.attachMediaElement(this.videoDom);
      this.flvPlayer.load();
      this.flvPlayer.play();
    }
  }

  /**
   * 销毁flv 播放
   */
  releaseFlvVide() {
    if (this.flvPlayer) {
      this.flvPlayer.pause(); //暂停播放数据流
      this.flvPlayer.unload(); //取消数据流加载
      this.flvPlayer.detachMediaElement(); //将播放实例从节点中取出
      this.flvPlayer.destroy(); //销毁播放实例
    }
  }

  playVideoStreamEvent = (url: string) => {
    this.videoSrc = url;
    if (!url) {
      return;
    }
    if (url.includes(".m3u8")) {
      this.playHlsVideo();
    } else {
      this.playFlvVideo();
    }
  };
}
</script>