import { httpGet, httpPost } from "@/api/gateway/HTTP";
import BASE_URL from "@/api/gateway/URL";

/**
 * 获取相机日志
 * @param params
 * @param success
 * @param fail
 */
export function requestCameraLogList(
  params: any,
  success: Function,
  fail: any = null
) {
  httpGet(BASE_URL.CAMERA_LOG_LIST, params, success, fail);
}

/**
 * 道口监控设备列表

 * @param params 
 * @param success 
 * @param fail 
 */
export function requestMonitorVideoList(
  params: any,
  success: Function,
  fail: any = null
) {
  httpGet(BASE_URL.CAMERA_MONITOR_LIST, params, success, fail);
}

/**
 *单个摄像头获取流地址
 * @param params
 * @param success
 * @param fail
 */
export function requestMonitorStream(
  params: any,
  success: Function,
  fail: any = null
) {
  httpGet(BASE_URL.MONITOR_PULL_STREAM, params, success, fail);
}

/**
 * 图片抓拍列表
 * @param params 
 * @param success 
 * @param fail 
 */
export function requestCameraPhotoList(
  params: any,
  success: Function,
  fail: any = null
) {
  httpGet(BASE_URL.CAMERA_PHOTO_LIST, params, success, fail);
}

/**
 * 图片抓拍
 * @param params 
 * @param success 
 * @param fail 
 */
export function requestCameraCapture(
  params: any,
  success: Function,
  fail: any = null
) {
  httpPost(BASE_URL.CAMERA_PHOTO_CAPTURE, params, success, fail);
}

/**
 * 手动开闸
 * @param params 
 * @param success 
 * @param fail 
 */
export function requestOpenGate(
  params: any,
  success: Function,
  fail: any = null
) {
  httpPost(BASE_URL.OPEN_GATE, params, success, fail);
}

/**
 * 手动关闸
 * @param params 
 * @param success 
 * @param fail 
 */
export function requestCloseGate(
  params: any,
  success: Function,
  fail: any = null
) {
  httpPost(BASE_URL.CLOSE_GATE, params, success, fail);
}
