import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_tab = _resolveComponent("van-tab")!
  const _component_van_tabs = _resolveComponent("van-tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_van_tabs, {
      active: 1,
      sticky: "",
      onClickTab: _ctx.onClickTab
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabList, (name) => {
          return (_openBlock(), _createBlock(_component_van_tab, {
            title: name,
            key: name
          }, null, 8, ["title"]))
        }), 128)),
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["onClickTab"])
  ]))
}