import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"
import _imports_0 from '../../assets/ic_monitor_blue.png'
import _imports_1 from '../../assets/ic_monitor.png'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  style: {"width":"40px","height":"40px"}
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  style: {"width":"40px","height":"40px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_grid_item = _resolveComponent("van-grid-item")!
  const _component_van_grid = _resolveComponent("van-grid")!
  const _component_van_list = _resolveComponent("van-list")!

  return (_openBlock(), _createBlock(_component_van_list, {
    loading: _ctx.loading,
    "onUpdate:loading": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loading) = $event)),
    finished: _ctx.finished,
    "finished-text": "没有更多了",
    onLoad: _ctx.onMoreLoad
  }, {
    default: _withCtx(() => [
      _createVNode(_component_van_grid, { "column-num": 3 }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listData, (item) => {
            return (_openBlock(), _createBlock(_component_van_grid_item, {
              key: item.id,
              onClick: ($event: any) => (_ctx.onClickSelectItemEvent(item))
            }, {
              text: _withCtx(() => [
                _createElementVNode("div", null, [
                  (item.id == _ctx.videoId)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_1))
                    : (_openBlock(), _createElementBlock("img", _hoisted_2)),
                  _createElementVNode("div", {
                    style: _normalizeStyle(item.id == _ctx.videoId? 'color:cornflowerblue':'color:black')
                  }, _toDisplayString(item.name), 5)
                ])
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["loading", "finished", "onLoad"]))
}