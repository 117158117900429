<template>
  <div>
    <van-tabs :active="1" sticky @click-tab="onClickTab">
      <van-tab :title="name" v-for="name in tabList" :key="name" />
      <slot />
    </van-tabs>

  </div>
</template>


<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["bindTabItemClickEvent"],
})
export default class NavTabs extends Vue {
  tabList: string[] = ["实时视频", "图片列表", "最近日志"];
  active: number = 1;

  onClickTab = (event: any) => {
    console.log("-->name: ", event.name);
    console.log("-->title: ", event.title);
    this.$emit("bindTabItemClickEvent", event.name);
  };
}
</script>

<style scoped>
</style>