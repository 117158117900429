import {
  showDialog,
  showLoadingToast,
  showFailToast,
  showToast,
  closeToast,
  showSuccessToast,
} from "vant";
// 弹出提示
export function alertMsg(config: any) {
  showDialog({
    title: config.title || "",
    message: config.content || "",
    confirmButtonText: config.confirmButtonText || "确定",
  }).then(() => {
    config.callback && config.callback();
  });
}
/**
 * 显示提示
 * @param {*} type
 * @param {*} content
 */
export function showToastNormal(content: string) {
  showToast(content);
}
export function showToastError(content: string) {
  showFailToast(content);
}
export function showToastSuccess(content: string) {
  showSuccessToast(content);
}

export function showLoading(content: string) {
  showLoadingToast({
    message: content,
    forbidClick: true,
    duration: 0, //展示时长(ms)，值为 0 时，toast 不会消失
  });
}
export function closeLoading() {
  closeToast();
}
